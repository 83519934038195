<template lang="pug">
  div
    v-data-table(
      :headers="headers"
      :items="billingList"
      dense
      :loading="billingGetting"
      :server-items-length="billingGetCount"
    )
      template(v-slot:item="{ item, expand, isExpanded }")
        tr
          td {{ getBillingNumber(item.id) }}
          td {{ (item.customer || {}).company }}
          td {{ (item.created_at || '').dateFormat() }}
          td.success--text {{ item.completed ? 'COMPLETED' : null }}
          td
            v-btn(
              text
              color="purple"
              small
              @click="$router.push(`/orders/default/billings/${item.id}/show`)"
            )
              v-icon mdi-eye
            v-btn(
              text
              small
              color="green"
              @click="printBill(item)"
            )
              v-icon mdi-cash-multiple
            v-btn(
              text
              small
              color="blue-grey darken-2"
              @click="expand(!isExpanded)"
            )
              v-icon(
                :class="isExpanded ? 'is-expanded' : ''"
              ) mdi-chevron-down
      template(v-slot:expanded-item="{ item }")
        tr
          td(colspan="4")
            v-simple-table(dense)
              template(v-slot:default)
                thead
                  tr
                    th Invoice No.
                    th Particulars
                    th Amount
                tbody
                  tr(v-for="detail in item.details" :key="detail.id")
                    td {{ drFormat(detail.invoice_id) }}
                    td {{ detail.particulars }}
                    td {{ detail.amount.phpFormat() }}
</template>
<style lang="sass">
  .is-expanded
    transform: rotate(-180deg)
</style>
<script>
import billingRepository from '@/repositories/billing.repository'
import VueRequestHandler from '@/libs/classes/VueRequestHandler.class'
import { requestVars } from '@/libs/api-helper.extra'
import hericaBillingPdf from '@/pdfs/herica-billing.pdf'

const [billingGetVars, billingGetVarNames] = requestVars({ identifier: 'billing', pagination: true })

const billingGetHandler = new VueRequestHandler(null, billingGetVarNames, {}, true)

const tableVars = () => ({
  headers: [
    { text: 'No.', value: 'id' },
    { text: 'Client Name', value: 'contact_name' },
    { text: 'Date', value: 'created_at' },
    { text: 'Status', value: 'completed' },
    { text: 'Config', sortable: false },
  ],
})

export default {
  name: 'BillingTable',
  data () {
    return {
      ...tableVars(),
      ...billingGetVars,
    }
  },
  created () {
    this.getBillings()
  },
  methods: {
    getBillings () {
      const handler = billingGetHandler
      const repository = billingRepository.index
      const params = this.getBillingIndexParams()
      handler.setVue(this)
      handler.execute(repository, [params])
    },
    printBill (item) {
      const billingFor = {
        companyName: item.customer.company,
        contactName: item.contact_name,
        phone: item.contact_number || '',
        email: item.email || '',
        billingNo: this.getBillingNumber(item.id),
        date: (item.created_at || '').dateFormat(),
      }
      const billingStatement = {
        items: this.getBillingStatementItems(item),
        totalAmount: this.getBillingStatementItemsTotalAmount(item),
      }
      hericaBillingPdf.generate(billingFor, billingStatement)
    },
    getBillingStatementItemsTotalAmount (billing) {
      return billing.details.reduce((total, item) => total + item.amount, 0).moneyFormat()
    },
    getBillingStatementItems (billing) {
      const minLength = 15
      const list = billing.details.map((subItem, index) => ({
        item: index + 1,
        invoiceNo: this.drFormat(subItem.invoice_id),
        date: (subItem.date || '').dateFormat(),
        particulars: subItem.particulars,
        amount: (subItem.amount || 0).moneyFormat(),
      }))
      if (minLength <= list.length) return list
      return list.concat(Array(minLength - list.length).fill({}))
    },
    getBillingIndexParams () {
      return {

      }
    },
    getBillingNumber (id) {
      return `BL${id.toString().padStart(6, '0')}`
    },
    drFormat (invoice) {
      return `DR${invoice.toString().padStart(6, '0')}`
    },
  },
}
</script>